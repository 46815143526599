// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.root .form {
  width: 80%;
}
.root .incitingText {
  color: #1900D5;
  cursor: pointer;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;AACF;AAAE;EACE,UAAA;AAEJ;AAAE;EACE,cAAA;EACA,eAAA;EACA,iBAAA;AAEJ","sourcesContent":[".root {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100vw;\n  height: 100vh;\n  .form {\n    width: 80%;\n  }\n  .incitingText {\n    color: #1900D5;\n    cursor: pointer;\n    margin-left: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
