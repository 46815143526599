export enum pages{
	home='/',
	projects = '/projects',
	about = '/about',
	login = '/login',
	register = '/register',
	favorites = '/favorites',
	users = '/users',
	setup = '/setup',
}
